.CustomerDetails {
    .tabs {
        margin: 20px 0 30px;
    }
    h5 {
        margin-bottom: 20px;
    }
    label {
        font-size: .85rem;
        font-weight: 500;
    }
}

.PoliciesTab, .PendingQuotes {
    thead {
        display: table-header-group !important;
    }
    .NoFound {
        font-size: 1rem;
    }
    .tableRow {
        cursor: pointer;

        &:hover {
            background-color: #f3f3f3;
        }
    }
}