.endorsementsTab {


  .container-dates-picker {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .row {
      display: flex;
      flex: 1;
      min-width: 0; /* Allow columns to shrink beyond their content width */
    }

    .column {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      min-width: 0; /* Allow columns to shrink beyond their content width */
    }
  }








  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.trip-extension {
        margin-bottom: 1rem;
    }
  }

  .button {
    height: 34.8px;

    &.ml {
      margin-left: .5rem;
    }
  }

  span {
    font-size:1rem;
  }

  .trip-duration-section {
    margin-bottom: 2rem;

    .extended-dates-inactive {
      opacity: 0.3;
    }
    .dates-picker-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.initial-dates {
        margin-bottom: 1rem;
      }

      .MuiInputBase-root {
        width: 7rem !important;
        display: inline-block !important;
      }

      .start-date {
        margin-right: 0.2rem;
      }

      .end-date {
        margin-left: 1rem;
        margin-right: 0.2rem;
      }
    }

    .label {
      font-weight: bold;
    }
  }

  .valuables-section {
    margin-top: 2rem;
  }

  .endorsements-table {
    .no-data {
      text-align: center;
      margin: 1rem;
      font-size: 1.1rem;
    }
  }

  .button-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    svg {
      margin-top: 0;
      font-size: 1.2rem;
    }
  }
}
