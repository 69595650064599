.customerTab {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    .button {
        height: 34.8px;

        &.ml {
            margin-left: .5rem;
        }
    }

    .field-groups-container {
        display: grid;
        gap: 1rem;
    }

    .field-group {
        display: grid;
        gap: 1rem;
    }

    .field-groups-container .MuiInputBase-root {
        background-color: transparent !important;
    }

    .label-text {
        font-weight: bold;
        margin-right: 6px;
    }

    .text-value {
        
    }

    .field-container {
        display: flex;
        align-items: center;
        font-size: 1rem;
    }
    
    .MuiInputBase-root {
        //margin-left: .5rem;
        max-width: 193px;
    }

    .input {
        width: 185px;
    }
}
