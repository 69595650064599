.card-shortcut {
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  .brand-name {
    &:hover {
      font-weight: 500;
    }
  }
}
