.PendingQuotes {
    .search-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .search-input {
        flex: 1;
        margin-right: 1rem;
        color: #000;
    }

}
