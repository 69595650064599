.card-shortcut {
  height: 8rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 4rem;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
  }

  .company-logo {
    display: block;
    height: auto;
    width: auto;
    max-height: 100%;
  }

  .card-content {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }

}
