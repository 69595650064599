.customerDetails-customerTab {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }
    
    .button {
        height: 34.8px;
    
        &.ml {
            margin-left: .5rem;
        }
    }
    
    .field-groups-container {
        display: grid;
        gap: 1rem;
    }
    
    .field-group {
        display: grid;
        gap: 1rem;
    }
    
    .field-groups-container .MuiInputBase-root {
        background-color: transparent !important;
    }
    
    /* Custom layout styles */
    .field-group.twoColumns {
        grid-template-columns: 1fr 1fr;
    }
    
    .field-group.threeColumns {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .field-group.fourColumns {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    
    .field-group.fourColumnsFirstNarrow {
        grid-template-columns: 0.4fr 1fr 1fr 1fr;
    }
    
    .error-message {
        color: red;
        font-size: 12px;
        margin: 4px 0 0;
    }
}
