.travelersTab {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }
    
    .button {
        height: 34.8px;

        &.ml {
            margin-left: .5rem;
        }
    }

    .previewButton {
        margin-top: 20px;
    }

    .field-container {
        display: flex;
        flex-wrap: wrap;
        font-size: 1rem;
    }

    .field {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        margin-bottom: 16px;
        height: 100%;
    }

    .field.editable {
        background-color: #f4f4f4;
        padding: 16px;
    }

    .field-label {
        font-weight: bold;
        margin-bottom: 8px;
    }

    .field-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &:nth-last-of-type(2) {
            margin-bottom: auto;
        }
    }

    .field-item strong {
        margin-right: 8px;
        flex: 0 0 auto;
        width: 120px;
    }

    .field .MuiInputBase-root {
        max-width: 185px;
        margin: 0;
    }

    .MuiGrid-root .MuiGrid-item {
        padding-bottom: 1rem;
    }

    @media (max-width: 768px) {
        .field {
            flex-basis: 100%;
            margin-right: 0;
        }
    }
}

.beneficiariesTab-medicalConditions {
    h2 {
      font-size: 1rem;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    > div {
      margin-bottom: 20px;
    }

    p {
      margin: 10px 0;
    }
  }

.beneficiariesTab-noMedicalConditions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}