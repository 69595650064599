.PolicyDetails {
    .tabs {
        margin: 20px 0 30px;
    }

    h5 {
        margin-bottom: 20px;
    }

    label {
        font-size: .85rem;
        font-weight: 500;
    }

    .policyInfo {
        font-size: 1rem;
    }

    .buttonsContainer {
        display: flex;
        margin-top: 20px;

        button:not(:first-child) {
            margin-left: 10px;
        }

        .additionalButtons {
            margin-left: auto;
        }
    }
}
