.paymentTab {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .label-text {
        font-weight: bold;
    }

    .text-value {
        margin-left: 8px;
    }

    .field-container {
        display: flex;
        align-items: center;
        font-size: 1rem;
    }
}
