.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background-color: #02020236;

  .spinner {
    width: 5rem;
    height: 5rem;
    border: 0.8rem solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s infinite linear;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
