.Users {
    .searchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
    }
    .searchInputs {
        display: flex;
        align-items: center;
        flex: 1;
    }
    .search-input {
        margin-right: 10px;
        flex: 1;
    }
    .searchButton {
        flex-shrink: 0;
    }
    .paginationContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .paginationArrow {
        padding: 5px;
        cursor: pointer;
    }
    .tableRow {
        cursor: pointer;

        &:hover {
            background-color: #f3f3f3;
        }
    }
}