.policyTab {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }
    
    .label-text {
        font-weight: bold;
    }

    .text-value {
        margin-left: 8px;
    }

    .MuiGrid-item.field-container {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 1px;
        font-size: 1rem;
    }
}