.icon-collapse{
    position: absolute;
    left: 210px;
    top: 45%;
    z-index: 999;
    cursor: pointer;
    opacity: 0.5;
    background-color: #d7d7d7;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    &:hover{
        opacity: 1;
    }
}

.collapsed {
    position: absolute;
    left: 65px;
}
