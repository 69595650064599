.PendingQuotesTable {
    .search-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }
    .search-input {
        flex: 1;
        margin-right: 1rem;
    }
    .paginationContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .paginationArrow {
        padding: 5px;
        cursor: pointer;
    }
    .tableRow {
        cursor: pointer;

        &:hover {
            background-color: #f3f3f3;
        }
    }
}